import React from "react"
import MyAccount from "../../../components/MyAccount"
import PrivateRoute from "../../../components/auth/PrivateRoute/loadable"

const AccountSettingsPage = props => (
  <PrivateRoute url={props.location.href}>
    <MyAccount />
  </PrivateRoute>
)

export default AccountSettingsPage
