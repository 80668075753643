import React from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import Auth from "@aws-amplify/auth"
import {
  Button,
  Grid3TopBar,
  GridBox,
  GridContainer,
  Form,
  Text,
  Title,
  makeRem,
} from "@thedgbrt/logology-ui-components"
import { Link } from "gatsby"
import { signOutUser } from "../../state/actions/user"

const MyAccount = ({ email, signOutUser }) => {
  const handleSignOut = async () => {
    await signOutUser(Auth)
    navigate("/")
  }

  return (
    <>
      <Grid3TopBar>
        <Link to={`/myaccount/proposals/`}>← back to proposals</Link>
      </Grid3TopBar>
      <GridContainer type={4}>
        <GridBox colSpans={[[2, 4], [2, 4], [2, 6]]} type={4}>
          <Title level={2} standalone>
            My Account
          </Title>
        </GridBox>
      </GridContainer>
      <GridContainer type={4}>
        <GridBox colSpans={[[2, 4], [2, 4], [2, 6]]} type={4} withBorder>
          <Text lead standalone>
            <ul style={{ fontStyle: "normal" }}>
              <li>
                <Link to="/myaccount/settings/change-name/">
                  update my brand name
                </Link>
              </li>
              <li>
                <Link to="/myaccount/settings/change-activity/">
                  select a different activity
                </Link>
              </li>
              <li>
                <Link to="/myaccount/onboarding/?retake=true">
                  re-take questionnaire
                </Link>
              </li>
              <li>
                <Link to="/forgot-password/">change my password</Link>
              </li>
            </ul>
          </Text>
        </GridBox>
      </GridContainer>
      <GridContainer
        type={4}
        rowsMobile={[makeRem(20)]}
        rowsTablet={[makeRem(20)]}
        rowsDesktop={[makeRem(20)]}
      ></GridContainer>
      <GridContainer type={4}>
        <GridBox colSpans={[[2, 4], [2, 4], [2, 6]]} type={4}>
          <Text standalone>My email address: {email}</Text>
          <Text standalone>
            Contact us at{" "}
            <a href="mailto:support@logology.co">support@logology.co</a> to
            change your email address or delete your account.
          </Text>
          <Form inline standalone>
            <Button onClick={handleSignOut}>sign out</Button>
          </Form>
          <Text>&nbsp;</Text>
        </GridBox>
      </GridContainer>
    </>
  )
}

export default connect(
  ({ user }) => ({ email: user.email, profile: user.profile }),
  { signOutUser }
)(MyAccount)
